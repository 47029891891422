<template>
  <comercial-tpl>
    <b-card
      title=""
      style="max-width: 40rem; margin-top: 5%"
      class=" mx-auto"
      
      align-v="center">

      <b-row>
        
        <b-col>
          <router-link to="/indique/listar"> 
            <b-icon icon="people" font-scale="4.5"></b-icon><br>
            Indicados  
          </router-link>
        </b-col>

        <b-col>
          <router-link to="#">
            <b-icon icon="clipboard-check" font-scale="4.5"></b-icon><br>           
            Matriculados
          </router-link>          
        </b-col>
        
        <b-col>
          <router-link to="/indique/ranking">
          <b-icon icon="trophy" font-scale="4.5"></b-icon><br>
          Ranking
          </router-link>
        </b-col>

        <b-col>
          <a v-on:click="sair()">
            <b-icon icon="door-open" font-scale="4.5"></b-icon><br>           
            Sair
          </a>         
        </b-col>

      </b-row>
      
      </b-card>
  </comercial-tpl>
</template>

<script>
// @ is an alias to /src
import ComercialTpl from '@/tpls/ComercialTpl.vue'

export default {
  name: 'Comercial',
  components: {
    ComercialTpl
  },

  methods:{
    sair(){
      this.$store.commit('setUsuario',null);
      sessionStorage.clear();
      this.usuario = false;
      this.$router.push('/');
    }
  }  
}
</script>
